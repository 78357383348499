.header {
  color: var(--ion-color-base);
  margin-block: 1.6em 0.4em;
}
:global(.ios) .header {
  font-size: 80%;
  text-transform: uppercase;
}
:global(.md) .header {
  font-size: 85%;
  font-weight: 500;
}

.header + :global(ion-list.list-inset) {
  margin-block-start: 0;
}
