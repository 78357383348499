.row {
  display: flex;
  margin: var(--ion-margin, 16px);
}
.noTopMargin {
  margin-block-start: 0;
}
.noBottomMargin {
  margin-block-end: 0;
}

.card {
  flex: 1;
}
.card:global(.list-inset) {
  margin: 0;
}
.card:not(:first-child) {
  margin-inline-start: var(--ion-margin, 16px);
}
.label {
  margin-inline-end: 0;
}