.note {
  color: var(--ion-color-base);
  margin-block: 0.4em 1.6em;
  font-size: 80%;
}

/* Selecting the previous sibling */
:global(ion-list.list-inset):has(+ .note) {
  margin-block-end: 0;
}
