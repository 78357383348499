.root {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 15px;
}

.button {
  margin: 20px auto 0;
}
