.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  margin-inline-end: 0.5em;
}
.value {
  color: var(--ion-color-base);
  font-size: 125%;
  font-weight: 500;
  text-align: end;
}
.subValue {
  color: var(--ion-color-base);
  text-align: end;
  font-weight: bold;
  font-size: 82%;
}
.subValue > ion-progress-bar:first-child {
  margin-block-start: 0.5em;
}
.subValue > ion-progress-bar::part(progress) {
  /* Анимация прогресс-бара не заметна, но она жрёт батарейку, поэтому отключаем её */
  transition: none;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
