.monospace-digits {
  font-variant-numeric: tabular-nums;
}

.headerless-content {
  --padding-top: var(--ion-safe-area-top, 0);
}

/* Исправляет не работающий `text-overflow: ellipsis;` в названиях вкладок */
ion-tab-button {
  overflow: hidden;
}
